import Cookies from 'js-cookie';
import dynamic from 'next/dynamic';

import useLoggedUser from '../../../hooks/use-logged-user';
import { ADVANCED_VIEW_ACTIVE } from '../../../static/cookies';

const Default = dynamic(() => import('./default'));
const Checkout = dynamic(() => import('./checkout-header'), { ssr: false });
const AdvancedSearch = dynamic(() => import('./advanced-search-header'), {
  ssr: false,
});

const Header = ({ type, defaultProps }: THeader) => {
  const user = useLoggedUser();
  const isAdvanced = !!parseInt(Cookies.get(ADVANCED_VIEW_ACTIVE) || '0');

  if (user?.staff.is_pro && isAdvanced) {
    return <AdvancedSearch />;
  }

  switch (type) {
    case 'NONE':
      return null;
    case 'ADVANCED-SEARCH':
      return <AdvancedSearch />;
    case 'CHECKOUT':
      return <Checkout />;
    case 'DEFAULT':
    default:
      return <Default {...defaultProps} />;
  }
};

export default Header;
